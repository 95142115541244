<template>
  <div>
    <section
  
    >
      <div class="" style="margin-left: 30px; margin-right: 30px">
        <div class="row">
          <div class="col-sm-12 overflow-hidden">
            <div
              class="iq-main-header d-flex align-items-center"
              v-if="posts.length > 0"
            >
              <h4 class="main-title mt-4">
                {{title}}
              </h4>
            </div>

            <VueSlickCarousel
              v-bind="settings"
              v-if="posts.length"
            >
              <div
                v-for="(post, i) in posts"
                v-bind:key="i"
              >
                <post-card :post="post" :number="i+1"  v-if="showNumber"></post-card> 
                <post-card :post="post"  v-if="!showNumber" ></post-card>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PostCard from "@/components/PostCard.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    PostCard,
    VueSlickCarousel
  },
  props: ["posts", "title", "showNumber"],
  data() {
    return {
   
            settings: {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
           {
            breakpoint: 375,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
           
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ],
      },
    };
  },
  mounted() {
  
  },

  methods: {},
};
</script>

<style>

.overflow-hidden {
  overflow: unset;
}
</style>