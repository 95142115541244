<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mt-3 mb-3">
          <span class="text-muted">Section > </span><span>Favorites</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <slider title="My Favorites" :posts="posts"></slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import slider from "../components/Slider.vue";

export default {
  components: { slider },
  data() {
    return {
      posts: [],
    };
  },
  mounted() {
      let _this = this;
    this.$bind("posts", db.collection("posts")).then(function () {
let postsFavorites = []
       _this.posts.forEach(function(post){
           if (post.favorites && post.favorites.includes(_this.$store.state.currentUser.uid)){
                 console.log(post)
                 postsFavorites.push(post);
                

           }
           _this.posts = postsFavorites;
         
       })
    });
  },
};
</script>

<style>
</style>